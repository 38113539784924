import React, { Component } from "react";
import ReactDOM from "react-dom";
import styles from "./index.css";
import * as THREE from 'three';
import Coaster  from './coaster';

export class App extends Component {
  componentDidMount() {
    var scene,camera, renderer, cloudParticles = [], flash, flash2;
    const init = () => {

      const yellowLight = new THREE.Color("hsl(59,63%,60%)");
      const purpleLight = new THREE.Color("hsl(276,100%,33%)");
      const blackLight  = new THREE.Color("hsl(80,14%,4%)");
      const blueLight = new THREE.Color("rgb(61,131,187)");

      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(15,window.innerWidth / window.innerHeight, 1, 1000);
      camera.position.z = 1;
      camera.rotation.x = 1.16;
      camera.rotation.y = -0.12;
      camera.rotation.z = 0.47;


      let ambient = new THREE.AmbientLight(blackLight, 15);
      scene.add(ambient);

    let directionalLight = new THREE.DirectionalLight(purpleLight);
      directionalLight.position.set(100,100,1);
      scene.add(directionalLight);

      flash = new THREE.PointLight(yellowLight, 55, 300 ,1.8);
      flash.position.set(200,100,100);
      scene.add(flash);

      flash2 = new THREE.PointLight(blackLight, 1, 450 ,1.99);
      flash2.position.set(0,450,50);
      scene.add(flash2);

      renderer = new THREE.WebGLRenderer();
      scene.fog = new THREE.FogExp2(purpleLight, 0.002);
      renderer.setClearColor(scene.fog.color);
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.mount.appendChild(renderer.domElement);

      // scene.add(rain);
      let loader = new THREE.TextureLoader();
      loader.load("chrome.png", function(texture){

        let cloudGeo = new THREE.PlaneBufferGeometry(200,150);
        let cloudMaterial = new THREE.MeshLambertMaterial({
          map: texture,
          transparent: true
        });

        for(let p=0; p<300; p++) {
          let cloud = new THREE.Mesh(cloudGeo,cloudMaterial);
          cloud.position.set(
            Math.random()*400 -100,
            500,
            Math.random()*400 - 350
          );
          cloud.rotation.x = 1.16;
          cloud.rotation.y = -0.12;
          cloud.material.opacity = 0.2;
          cloudParticles.push(cloud);
          scene.add(cloud);
        }
        animate();
      });
    }
    function animate() {
      cloudParticles.forEach(p => {
        p.rotation.z -=0.019;
        p.velocity += 0.2 + Math.random() * 0.1;
        p.y += p.velocity;
        p.rotation.x +=0.0001;
      });

      if(Math.random() > 0.93 || flash.power > 100) {
        if(flash.power < 100)
          flash.position.set(
            Math.random()*400,
            300 + Math.random() *150,
            100
          );
        flash.power = 60 + (Math.random() * 100 / 2) * 4
      }

      // if(Math.random() > 0.93 || flash2.power > 100) {
      //   if(flash2.power < 100)
      //   {
      //     flash2.position.set(
      //       Math.random()*100,
      //       150 + Math.random() *150,
      //       50
      //     );
      //   }
      //   flash2.power = 50 + (Math.random() * 100 / 2) * 4
      // }

      renderer.render(scene, camera);
      //window.setTimeout(() => scene.remove.apply(scene, scene.children), 15000);
      requestAnimationFrame(animate);
    }
    init();
  }
  render() {
    return <div ref={ref => (this.mount = ref)} />;
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Coaster />, rootElement);
