import React, { Component } from "react";
import * as THREE from 'three';
import { RollerCoasterShadowGeometry, RollerCoasterLiftersGeometry } from 'three/examples/jsm/misc/RollerCoaster.js';
import OBJLoader from 'three-obj-loader';
OBJLoader(THREE);

const {BufferGeometry, Vector3, Quaternion, BufferAttribute} = THREE;

const RollerCoasterGeometry = function ( curve, divisions ) {

  BufferGeometry.call( this );

  var vertices = [];
  var normals = [];
  var colors = [];

 // var color1 = [ 1, 1, 0 ];

  var up = new Vector3( 0, 1, 0 );
  var forward = new Vector3();
  var right = new Vector3();

  var quaternion = new Quaternion();
  var prevQuaternion = new Quaternion();
  prevQuaternion.setFromAxisAngle( up, Math.PI / 2 );

  var point = new Vector3();
  var prevPoint = new Vector3();
  prevPoint.copy( curve.getPointAt( 0 ) );

  // shapes
  var PI2 = Math.PI * 2;

  var sides = 6;
  var tube1 = [];
  var tube2 = [];
  var tube3 = [];

  for ( var i = 0; i < sides; i ++ ) {

    var angle = ( i / sides  ) * PI2 + 5
    tube1.push( new Vector3( Math.sin( angle ) * 1.6, Math.cos( angle ) * 0.26, .05 ) );

  }


  for ( var i = 0; i < sides; i ++ ) {

    var angle = ( i / sides  ) * PI2;
    tube2.push( new Vector3( Math.sin( angle ) * 3.6, Math.cos( angle ) * 3.6, 0 ) );
    //tube2.push( new Vector3( Math.sin( angle ) * 3, Math.cos( angle ) * 0.56, -1 ) );
  }


  for ( var i = 0; i < sides; i ++ ) {

    var angle = ( i / sides  ) * PI2;
    tube3.push( new Vector3( Math.sin( angle ) * 10, Math.cos( angle ) * 0.06, 3 ) );
  }
  // var tube2 = [];
  //
  // for ( var i = 0; i < sides; i ++ ) {
  //
  //   var angle = ( i / sides  ) * PI2;
  //   tube2.push( new Vector3( -Math.sin( angle ) * 1.3, -Math.cos( angle ) * 0.18, .05 ) );
  //
  // }


  var vector1 = new Vector3();
  var vector2 = new Vector3();
  var vector3 = new Vector3();
  var vector4 = new Vector3();

  var normal1 = new Vector3();
  var normal2 = new Vector3();
  var normal3 = new Vector3();
  var normal4 = new Vector3();

  function extrudeShape( shape, offset, color ) {

    for ( var j = 0, jl = shape.length; j < jl; j ++ ) {

      var point1 = shape[ j ];
      var point2 = shape[ ( j + 1 ) % jl ];

      vector1.copy( point1 ).add( offset );
      vector1.applyQuaternion( quaternion );
      vector1.add( point );

      vector2.copy( point2 ).add( offset );
      vector2.applyQuaternion( quaternion );
      vector2.add( point );

      vector3.copy( point2 ).add( offset );
      vector3.applyQuaternion( prevQuaternion );
      vector3.add( prevPoint );

      vector4.copy( point1 ).add( offset );
      vector4.applyQuaternion( prevQuaternion );
      vector4.add( prevPoint );

      vertices.push( vector1.x, vector1.y, vector1.z );
      vertices.push( vector2.x, vector2.y, vector2.z );
      vertices.push( vector4.x, vector4.y, vector4.z );

      vertices.push( vector2.x, vector2.y, vector2.z );
      vertices.push( vector3.x, vector3.y, vector3.z );
      vertices.push( vector4.x, vector4.y, vector4.z );

      //

      normal1.copy( point1 );
      normal1.applyQuaternion( quaternion );
      normal1.normalize();

      normal2.copy( point2 );
      normal2.applyQuaternion( quaternion );
      normal2.normalize();

      normal3.copy( point2 );
      normal3.applyQuaternion( prevQuaternion );
      normal3.normalize();

      normal4.copy( point1 );
      normal4.applyQuaternion( prevQuaternion );
      normal4.normalize();

      normals.push( normal1.x, normal1.y, normal1.z );
      normals.push( normal2.x, normal2.y, normal2.z );
      normals.push( normal4.x, normal4.y, normal4.z );

      normals.push( normal2.x, normal2.y, normal2.z );
      normals.push( normal3.x, normal3.y, normal3.z );
      normals.push( normal4.x, normal4.y, normal4.z );

      // colors.push( color[ 0 ], color[ 1 ], color[ 2 ] );
      // colors.push( color[ 0 ], color[ 1 ], color[ 2 ] );
      // colors.push( color[ 0 ], color[ 1 ], color[ 2 ] );
      //
      // colors.push( color[ 0 ], color[ 1 ], color[ 2 ] );
      // colors.push( color[ 0 ], color[ 1 ], color[ 2 ] );
      // colors.push( color[ 0 ], color[ 1 ], color[ 2 ] );

    }

  }

  var offset = new Vector3();

  for ( var i = 1; i <= divisions; i ++ ) {

    point.copy( curve.getPointAt( i / divisions ) );

    up.set( 0, 1, 0 );

    forward.subVectors( point, prevPoint ).normalize();
    right.crossVectors( up, forward ).normalize();
    up.crossVectors( forward, right );

    var angle = Math.atan2( forward.x, forward.z );

    quaternion.setFromAxisAngle( up, angle );


    //extrudeShape( tube1, offset.set( .5, 0, .1));

    //extrudeShape( tube2, offset.set( -.5, -0.5, 3));

    // extrudeShape( tube3, offset.set( 5, 5, 5));

    extrudeShape( tube2, offset.set( 50, 50, 50));

    extrudeShape( tube2, offset.set( 150, 150, 150));

    extrudeShape( tube2, offset.set( 350, 50, -10));

    extrudeShape( tube2, offset.set( 450, 150, -100));

    prevPoint.copy( point );
    prevQuaternion.copy( quaternion );

  }

  // console.log( vertices.length );

  this.addAttribute( 'position', new BufferAttribute( new Float32Array( vertices ), 3 ) );
  this.addAttribute( 'normal', new BufferAttribute( new Float32Array( normals ), 3 ) );
  this.addAttribute( 'color', new BufferAttribute( new Float32Array( colors ), 3 ) );

};

RollerCoasterGeometry.prototype = Object.create( BufferGeometry.prototype );


const cloudParticles = [];

const createRollerCoaster = (ref) => {
  const renderer = new THREE.WebGLRenderer( { antialias: true } )
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  ref.appendChild(renderer.domElement);
  const yellowLight = new THREE.Color("hsl(54,79%,45%)");
  const purpleLight = new THREE.Color("hsla(295,87%,17%,0.88)");
  const blackLight  = new THREE.Color("hsla(270,40%,2%,0.8)");
  const blueLight = new THREE.Color("rgb(61,131,187)");

  // scene
  var scene = new THREE.Scene();
   // scene.background = yellowLight
  scene.fog = new THREE.Fog(purpleLight, .01, 95);
  var train = new THREE.Object3D();
  scene.add(train);
  var camera = new THREE.PerspectiveCamera(165, window.innerWidth / window.innerHeight, .01, 450);
  train.add(camera);


  const PI2 = Math.PI * 2;

  const generateCurveUtils = () => {
    const vector = new THREE.Vector3();
    const vector2 = new THREE.Vector3();
    return {
      getPointAt: function (t) {
        t = t * PI2;
        var x = Math.sin(t * 30) * Math.cos(t * 2) * 50;
        var y = Math.sin(t * 50) * Math.cos(t * 2) * 50;
        var z = Math.sin(t + 2) * Math.sin(t * 2) * 50;
        return vector.set(x, y, z).multiplyScalar(2);
      },
      getTangentAt: function (t) {
        var delta = 0.000001;
       //var delta = -.2;
        var t1 = Math.max(.1, t - delta);
        var t2 = Math.min(2, t + delta);
        return vector2.copy(this.getPointAt(t2))
          .sub(this.getPointAt(t1)).normalize();
      }
    };
  };


  const curve = generateCurveUtils();

  let loader = new THREE.TextureLoader();
  loader.load("chrome.png", function(texture) {

    let cloudMaterial = new THREE.MeshLambertMaterial({
      map: texture,
      lightMap: texture,
      // vertexColors: THREE.VertexColors,
      // transparent: true,
       color: purpleLight

    });
    var geometry = new RollerCoasterGeometry(curve, 750);
    var material = new THREE.MeshPhongMaterial({
      //vertexColors: THREE.VertexColors,
      map: texture,

      transparent: false
    });

    material.map = texture;

    var mesh = new THREE.Mesh(geometry, material);
     scene.add(mesh);

  });

  window.addEventListener('resize', onWindowResize, false);

  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

//
  var position = new THREE.Vector3();
  var tangent = new THREE.Vector3();
  var lookAt = new THREE.Vector3();
  var velocity = 0;
  var progress = 0;
  var prevTime = performance.now();

  function render() {
    var time = performance.now();
    var delta = time - prevTime;
    progress += velocity;
   // progress = progress % 2;
    position.copy(curve.getPointAt(progress));
    // position.y -= 0.1;
    train.position.copy(curve.getPointAt(progress));
    tangent.copy(curve.getTangentAt(progress));
     // velocity += tangent.y * 0.000001 * delta;
    velocity = Math.max(0.00001, Math.min(0.00001, velocity));
    // train.lookAt(lookAt.copy(position));
      train.lookAt(lookAt.copy(position));
    // train.rotation.z += .25;
      // train.rotation.y -= .5;
     // train.rotation.x += 100;
    // camera.setFocalLength(20);
    camera.rotateOnWorldAxis(position, -.0003);
    renderer.render(scene, camera);
    prevTime = time;
  }

  renderer.setAnimationLoop(render);
};

export default class Coaster extends Component {
  componentDidMount() {
    createRollerCoaster(this.mount);
  }
  render() {
    return <div ref={ref => (this.mount = ref)} />;
  }
}
